.portofolio-section{
    min-height:100vh;
    padding: 0 !important;
    margin-bottom: 50px;
    border-bottom: 1px solid #f1583b;
}

.portofolio-section .container{
    padding-top: 14vh;
}

.portofolio-description{
    margin-top: 50px;
    margin-bottom: 100px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.portofolio-description h2{
    color: black;
    font-size: 50px;
    font-weight: bold;
}

.project-description h3{
    font-size: 38px;
    font-weight: bold;
}

.portofolio-description p{
    text-align: center;
    font-size: 18px;
    color: #5f6060;
}

@media only screen and (max-width: 768px) {
    .content-home{
        width: 100%;
    }  

    .portofolio-description h2{
        font-size: 46px;
    }

    .portofolio-description{
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .portofolio-description{
        width: 80%;
    }

    .portofolio-section{
        min-height: auto;
    }
}

