.membership{
    display: flex;
    flex-direction: column;
    margin: 30px 0;
}

.membership-type{
    padding: 30px 0;
    border-bottom: 1px solid gray;
}

.membership-type h3{
    text-align: center;
    font-size: 26px;
}

.membership-description{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.membership-description .price{
    text-align: center;
    font-size: 26px;
    color: green;
    padding: 30px 0;
}

.membership-description .btn{
    margin: 20px 30px 0px 30px;
    padding: 10px 40px;
    border-radius: 25px;
    border: 0.5px solid green;
    color: gray;
    background-color: white;
    width: 70%;
    margin: 30px auto;
}

#active .membership-type{
    background-color: green;
    color:white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 0;
}

#active .membership-description{
    border: 1px solid green;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

#active .membership-description .btn{
    background-color: green;
    color: white;
}

#active .membership-description .btn:hover{
    opacity: 0.7;
}


.membership-description .row{
    min-height: 60px;
}

