.price-section{
    height: 100vh;
}

.price-content{
    width: 60%;
    margin: 10vh auto 4vh auto;
}

.price-h2{
    color: black;
    height: 50px;
}

.price-section content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.price-content p{
    text-align: center;
    margin: 20px 0;
}

.button-options{
    display: flex;
    justify-content: center;
}

.price-options{
    margin: 30px 0;
}

.memberships-row{
    margin: 30px 0;
}

@media only screen and (max-width: 600px) {
    .price-content{
        width: 100%;
    }
}
