.summary-section h1{
    text-align: center;
    margin: 10vh 0;
}

.summary-section .participants{
    display: flex;
    justify-content: center;
    margin: 50px 0;
}

.summary-section .price span{
    text-align: center;
    font-size: 32px;
    color: green;
    padding-bottom: 30px;
}

.summary-section .price{
    display: flex;
    justify-content: center
}

.purchase .btn{
    margin: 20px 30px 0px 30px;
    padding: 10px 40px;
    border-radius: 25px;
    border: 0.5px solid green;
    color: black;
    background-color: white;
    width: 40%;
    margin: 30px auto;
}

.purchase .btn:hover{
    background-color: green;
    color: white;
}

.summary-section .purchase{
    display: flex;
    justify-content: center;
}

.summary-section .functionalities{
    border: 1px solid green;
    padding: 30px 10px;
}

.summary-section .participants span{
    width: 100%;
    text-align: center;
    font-size: 24px;
}

.summary-section .subscription{
    margin: 50px auto;
    width: 50%;
}

.summary-section .subscription span{
    text-align: center;
    font-size: 24px;
}

.summary-section .subscription .period{
    margin: 5px auto;
    padding: 5px 20px;
    border-radius: 25px;
    border: 0;
    background-color: green;
    color: white;
}

@media only screen and (max-width: 600px) {
    .purchase .btn{
        width: 80%;
    }
    
    .summary-section .subscription .period{
        width: 80%;
    }
}