.project{
    height: 26rem;
    padding: 40px 40px;
    display: flex;
    background-color: #bd585d;
    margin-bottom: 180px;
    width: 100%;
    display: flex;
}

.image{
    width: 50%;
}

.info{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    padding-left: 20px;
}

.info .logo{
    height: 4rem;
    width: 15rem;
    margin-bottom: 1rem;
}

.logo image{
    object-fit: contain;
}

.right{
    flex-direction: row-reverse;
}

.right .project-description{
    order: 2;
}

.left{
    
}

.info p{
    text-align: start;
    color: white;
    font-size: 20px;
}

.project img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.project .row img{
    width: 40px;
    height: auto;
}

.project span{
    color: white;
}

.project h2 {
    color: white;
    text-align: left;
    font-size: 40pt;
}

@media only screen and (max-width: 768px) {
    .left{

    }

    .right{
       
    }

    .project{
        padding: 40px 20px;
        height: auto;
        flex-direction: column;
    }

    .project .image, .project .info{
        width: 100%;
    }

    .project .info{
        margin-top: 1rem;
        padding: 0;
    }

    .project .info p{
       margin: 0;
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .left{
    }

    .right{
    }

    .project{
        padding: 40px 20px;
        height: auto;
        flex-direction: column;
        width: 80%;
        align-items: center;
        margin: auto;
    }

    .project .image{
        width: 80%;
    }

    .project .info{
        margin-top: 1rem;
        padding: 0;
        width: 80%;
    }

    .project .info p{
        margin: 0;
     }
}
