.option-button{
    margin: 10px 10px;
    padding: 10px 40px;
    border-radius: 25px;
    border: 1px;
    color: black;
}

.activeOption{
    margin: 10px 10px;
    padding: 10px 40px;
    border-radius: 25px;
    border: 0;
    background-color: green;
    color: white;
}

.option-button:hover{
    opacity: 0.7;
}