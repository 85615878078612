.membership-description li{
    list-style: none;
    font-size: 16px;
    padding: 10px 40px;
    text-align: center;
    color: black;
}

.membership-description ul{
    padding: 0;
}

.membership-description li .text{
    font-size: 16px;
}

.membership-description li span{
    display: flex;
    align-items: center;
    justify-content: center;
}

.membership-description .denied{
    text-decoration: line-through;
    color: gray;
}

.membership-description .info p{
    position: absolute;
    left: -30vh;
    padding: 10px;
    margin-right: 5vh;
    background-color: green;
    color: white;
    border-radius: 5px;
}

.membership-description li i{
    color: gray;
}