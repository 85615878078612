.service{
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 10px #5f6060;
    margin-bottom: 50px;
    padding: 20px 0;
    height: 280px;
}

.service ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.service li{
    list-style: none;
    font-size: 18px;
    margin: 10px 0;
}

.icon-container{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.icon-container img{
    height: 100%;
    width: 100%;
}

.service .description p{
    color: #5f6060;
    text-align: center;
    width: 80%;
    margin: auto;
}

.service .category{
    width: 100%;
}

.service .category h3{
    text-align: center;
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
    .service{
        margin-bottom: 100px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}